.intro {
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    margin-left: 5.06rem;
    margin-top: 5.69rem;
    margin-bottom: 7.06rem;
    margin-right: 7.06rem;
}

.intro h3 {
    color: #1C3A80;
    font-family: Montserrat;
    font-size: 2.25rem;
    font-weight: 700;
    margin: 0;
}


.intro p {
    margin: 0;
    color: #000;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 400;
}

@media (max-width: 720px) {
    .intro {
        margin: 0;
        padding: 2.31rem 1.81rem 7.62rem 1.69rem;
        gap: 1rem;
    }

    .intro h3 {
        color: #1C3A80;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.25rem;
        /* 216.667% */
    }

    .intro p {
        color: #000;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.5rem;
        /* 166.667% */
    }
}