.logo {
    margin-top: 3.12rem;
    margin-left: 5.06rem;
    margin-bottom: 2.44rem;
}

.brandLogo {
    max-width: 20vw;
}

.logo .menu {
    display: none;
}


@media (max-width: 720px) {

    .logo {
        margin: 0;
    }

    .logo .brandLogo {
        max-width: 34.17vw;
    }

    .wrapper-logo {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }

    .logo .menu {
        display: block;
    }
}