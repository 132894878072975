.hospital {
    text-align: center;
    background: #EBECFF;
}

.hospital .vital-svg {
    display: none;
}

.hospital .medic-team {
    transform: translateY(-50%);
}

.content-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.right-container {
    text-align: end;
    padding-top: 2rem;
}

.right-container img {
    max-width: 100%;
    padding-left: 4.75rem;
    filter: drop-shadow(0px 10px 50px #A3A7FF);
}

.hospital .left-container {
    display: flex;
    flex-direction: column;
}

.hospital .left-container p {
    color: #000;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: start;
    margin: 0;
    margin-left: 5.06rem;
    margin-right: 0.5rem;
    margin-bottom: 3.94rem;
}

.hospital a {
    text-decoration: none;
    margin-left: 5.06rem;
    border-radius: 3.125rem;
    background: #494E9D;
    flex-shrink: 0;
    text-align: center;
    padding: 1.75rem;
    color: #FFF;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 8.12rem;
    margin-top: 2.94rem;
}

.hospital .mob-a {
    display: none;
}

.hospital h3 {
    margin: 0;
    color: #1C3A80;
    font-family: Montserrat;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.hospital .list-of-item {
    color: #000;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625rem;
    margin-left: 4.5rem;
    padding: 0;
    text-align: start;
}

.hospital .list-of-item ul {
    margin: 0;
    padding-left: 1.75rem;
}

.hospital .list-of-item li {
    margin-bottom: 1rem;
}

@media (max-width: 720px) {
    .medic-team {
        display: none;
    }

    .right-container {
        display: none;
    }


    .hospital .vital-svg {
        display: block;
        max-width: 100%;
    }

    .hospital .vital-svg img {
        width: 100%;
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
        filter: drop-shadow(0px 10px 50px #A3A7FF);
    }

    .hospital .img-container {
        padding-left: 4.82rem;
    }

    .hospital h3 {
        margin: 0;
        padding: 2.25rem 3.25rem 2.06rem;
        color: #1C3A80;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.25rem;
        /* 216.667% */
    }

    .content-container {
        display: block;
    }

    .hospital .left-container {
        display: flex;
    }

    .hospital .left-container p {
        margin: 0;
        margin: 3.17rem 2rem 2.31rem 1.31rem;
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.625rem;
    }

    .hospital .left-container .list-of-item {
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        /* 162.5% */
        margin-left: 2.35rem;
    }

    .hospital .list-of-item li {
        margin-bottom: 1rem;
    }

    .hospital .desk-a {
        display: none;
    }



    .hospital .mob-a {
        width: 18.8125rem;
        height: 3.875rem;
        flex-shrink: 0;
        display: inline-block;
        margin: 0 auto;
        margin: 2.62rem auto 3.5rem;
        border-radius: 3.125rem;
        background: #494E9D;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.625rem;
        padding: 1.12rem 2.41rem;
        /* 130% */
    }
}