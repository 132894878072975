.patient {
    background: #D8E9E3;
    text-align: center;
    padding-bottom: 6rem;
}

.patient h3 {
    margin: 0;
    color: #007A4D;
    font-family: Montserrat;
    font-size: 2.25rem;
    font-weight: 700;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: start;
}

/* .grid-container .grid-left {

} */

.grid-container .grid-left img {
    max-width: 100%;
    padding-left: 5.25rem;
    padding-right: 5.31rem;
    padding-top: 4.13rem;
    ;
}

.patient .grid-right p {
    color: #000;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 3.19rem;
    margin-right: 4.06rem;
    padding-right: 0.5rem;
    line-height: 2.5rem;
    margin-bottom: 2.69rem;
}

.patient .grid-right div {
    margin-right: 4.38rem;
    color: #000;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 7.31rem;
}

.patient .grid-right ul {
    margin: 0;
    padding-left: 1.75rem;
}

.patient .grid-right ul li {
    margin-bottom: 1rem;
}

.patient .download {
    background: linear-gradient(90deg, #F7CE18 0%, #22976A 100%);
}


.patient .download p {
    margin: 0;
    padding-top: 4.12rem;
    padding-bottom: 4rem;
    color: #252525;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 600;
}

.faq-heading {
    color: #007A4D;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
    padding: 3.31rem 0;
}

.faq {
    padding-left: 5rem;
    padding-right: 5.06rem;
    border: 2px solid black;
}

.patient-icon {
    transform: translateY(-50%);
}




@media (max-width: 720px) {

    .grid-container {
        display: block;
    }

    .grid-container .grid-left {
        margin: 0 3.5rem 0;
    }

    .grid-container .grid-left img {
        padding: 0;
    }

    .patient-icon {
        display: none;
    }

    .patient h3 {
        color: #007A4D;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.25rem;
        /* 216.667% */
        padding: 2.37rem 0 1.75rem;
    }

    .patient .grid-right p {
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.625rem;
        /* 162.5% */
        margin: 0;
        padding: 0;
        margin: 0 2rem 1.75rem 1.88rem;
    }




    .patient .grid-right div {
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        /* 162.5% */
        padding: 1.75rem 2rem 4rem 2rem;
        margin: 0;
    }

    .patient .download p {
        color: #252525;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.625rem;
        /* 108.333% */
        padding: 2.12rem 0;
    }

    .faq-heading {
        color: #007A4D;
        text-align: center;
        font-family: Montserrat;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.25rem;
        /* 216.667% */
    }

    .faq {
        color: #000;
        font-family: Montserrat;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.625rem;
        /* 130% */
    }

    .faq {
        margin: 0;
        padding: 0;
    }

}