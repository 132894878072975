.footer {
    background: #494E9D;
    margin-bottom: 7.81rem;
}

.footer-icon {
    text-align: center;
    transform: translateY(-40%);
    max-width: 100%;
}

.footer-content {
    text-align: center;
    margin-top: 2rem;
    padding-bottom: 5.25rem;
}

.footer-content .contact {
    margin: 0;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 400;
}

.footer-content p span {
    color: #FFF;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
}

.footer-content ul li {
    list-style: none;
    text-decoration: underline;
}

.footer-content ul {
    padding: 0;
    display: flex;
    gap: 2.25rem;
    justify-content: center;
    margin: 2rem;
}

.footer-content ul,
.footer-content .brand {
    color: #FFF;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 400;
}

@media (max-width: 720px) {

    .footer-content ul {
        flex-direction: column;
        gap: 0;
        margin: 1rem;
    }

    .footer-content .contact {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        /* 162.5% */
    }

    .footer-content p span {
        color: #FFF;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.625rem;
    }

    .footer-content ul,
    .footer-content .brand {
        color: #FFF;
        font-family: Montserrat;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
    }

    .footer-content {
        margin: 0;
        padding: 0;
        margin: 0 auto 0;
        padding: 0 3rem 3.5rem;
    }

    .footer {
        margin-bottom: 0;
    }
}