.landing .landing-icon {
    display: none;
}

.landing h1,
.landing p {
    margin: 0;
}

.landing h1 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 2.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.25rem;
    /* 113.043% */
    max-width: 45vw;
}

.landing h1 span {
    color: #FFF;
    font-family: Montserrat;
    font-size: 2.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.25rem;
}

.landing p {
    color: #FFF;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    /* 120% */
    max-width: 34.22vw;
}

.landing {
    background: linear-gradient(89deg, #1E3D87 0%, #007A4D 100%);
    display: flex;
    flex-direction: column;
    gap: 1.87rem;
    padding-left: 5.06rem;
    padding-top: 5.81rem;
    padding-bottom: 5.75rem;
    position: relative;
}

.middle-aged-woman {
    max-width: 48vw;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-30%);
}


@media (max-width: 1170px) {
    .middle-aged-woman {
        transform: translateY(-20%);
    }
}

@media (max-width: 1060px) {
    .middle-aged-woman {
        transform: translateY(-10%);
    }
}

@media (max-width: 900px) {
    .middle-aged-woman {
        transform: translateY(-3%);
    }
}

@media (max-width: 720px) {

    .middle-aged-woman {
        display: none;
    }

    .landing .landing-icon {
        display: inline-block;
        width: 58vw;
    }

    .landing h1 {
        padding: 0;
        color: #FFF;
        font-family: Montserrat;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
        /* 100% */
        max-width: 85.84vw;
    }

    .landing h1 span {
        color: #FFF;
        font-family: Montserrat;
        font-size: 2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
    }

    .landing p {
        max-width: 85vw;
    }

    .landing {
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
        align-items: center;
        padding-bottom: 2.93rem;
    }
}