.qna {
    margin-left: 5rem;
    margin-right: 5.06rem;
    text-align: left;
    margin-bottom: 2rem;
}

.ques {
    color: #000;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.ans {
    color: #000;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 400;
    padding-bottom: 2rem;
}

@media (max-width: 720px) {
    .qna {
        margin-left: 2.5rem;
        margin-right: 2.12rem;
    }


    .ques {
        color: #000;
        font-family: Montserrat;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.625rem;
        /* 130% */
    }

    .ans {
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
    }
}