.bold {
    font-weight: bold;
}

.highlight {
    background-color: yellow;
}

.bold-underline {
    text-decoration: underline;
}

.pp-container {
    font-family: 'Times New Roman', Times, serif;
    margin: 1rem;
}

table {
    border-collapse: collapse;
    /* Makes borders collapse into one */
    width: 100%;
}

th,
td {
    border: 1px solid black;
    /* Adds border to table cells */
    padding: 8px;
    /* Adds space inside cells */
    text-align: left;
    /* Aligns text to the left */
}